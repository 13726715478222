@import"../../variables";
@import"program-box.scss";
@import"responsive-home";
.slide {
    width: 100%;
}
.carousel-control-prev, .carousel-control-next{
    width: 70px;
    font-size: 30px;
    color: $main;
    opacity: 1;
    &:hover{
        color: $main;
        opacity: 0.5;
    }
}
.container-slide {
    height: 100%;
    position: absolute;
    width: 45%;
    top: 0;
    left: 5%;
    .slide-caption {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        padding: 0 15px;
        transform: translateY(-50%);
        h2.title-slide {
            font-size: 40px;
        }
        .slide-text {
            p.text-body {
                font-size: 20px;
            }
        }
        .btn-slide {
            display: inline-block;
            background-color: #0c2041;
            padding: 10px 20px;
            align-items: center;
            text-transform: uppercase;
            border-radius: 4px;
            a {
                color: white;
            }
        }
    }
}

.carousel-indicators {
    li {
        background-color: $main;
    }
}

section.about {}
//.quote {
//    background-color: #0c2041;
//    text-align: center;
//    color: white;
//    padding: 5% 5%;
//}
.mentor{
    h4.name {
        font-size: 16px;
    }
}
.list-cus-say {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    .row {
        display: grid;
        grid-auto-flow: column;
        //gap: 10px;
        .col {
            background-color: $main;
            color: white;
        }
    }
    .testimonial {
        .content-text {
            height: 200px;
            overflow: auto;
            &::-webkit-scrollbar {
                width: 2px;
                background-color: #fff;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #000;
            }
        }
    }
}

.swiper-container {
    .swiper-slide {
        image {
            border-radius: 15px;
        }
    }
}

.swiper-slide {
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
section.course-sec {
background-color:#efefef ;

}
.partner {
    .list-partner {
        position: relative;
        .content-partner {
            .image {
                box-shadow: 0 5px 5px 0px rgb(0 0 0 / 16%);
                padding: 10px;
                background-color: #fff;
                border-radius: 20px;


            }


            .partner-text {
                position: absolute;
                bottom: -120%;
                left: 0;
                min-height: 100%;
                width: 85%;
                z-index: 9999   ;
                //transform: translate3d(86px, 68px, 0px) !important;
                //box-shadow: -1px -3px 10px 1px rgb(0 0 0 / 16%);
                border: unset;
                border-radius: 8px;
                padding: 10px;
                margin: 0 15px;
                background-color: $main;
                opacity: 0;
                visibility: hidden;
                transition: all 0.3s ease;
                p{
                color: #fff;
                    font-size: 14px;
                    text-align: center;
                }
                &::before{
                    content: "";
                    border-color: #707070;
                    top: -8px;
                    left: 45%;
                    position: absolute;
                    border-top: 0;
                    border-right: 10px solid transparent;
                    border-bottom: 10px solid $main;
                    border-left: 10px solid transparent;
                }
            }
            &:hover{
                .partner-text{
                    opacity: 1;
                    visibility: visible;
                    bottom: -100%;
                }
            }
        }
    }
}
.container.quote {
    background: #ffffff;
    margin-bottom: 5%;
    padding: 5% 0;
    text-align: center;
    box-shadow: 0 3px 10px rgb(0 0 0 / 8%);
}
.why {
    .swiper-slide .course-box {
        text-align: center;
        .icon-course {
            text-align: center!important;
            img {
                width: 70px;
            }
        }
        h4.title {
            font-size: 16px;
            text-align: center;
        }
        .sub-title {
            text-align: center;
        }
    }
}

.subscribe {
    .container{
        background-color: #efefef;
        border-radius: 20px;
        form {
            .input-group {
                width: 60%;
                margin: auto;
                input {
                    border-radius: 30px!important;
                    height: 45px;
                    border: 1px solid $main;
                    &:focus {
                        box-shadow: none;
                    }
                }
                button.subscribe-btn{
                    background-color: $main;
                    position: absolute;
                    right: 7px;
                    line-height: 15px;
                    border-radius: 30px;
                    height: 32px;
                    top: 6px;
                    padding: 5px 12px;
                    color: #ffffff;
                    z-index: 999;
                }
            }
        }
    }
}
//Responsive
@media (max-width: 1024px) {
    .carousel-indicators li{
        width: 15px;
    }
    .carousel-control-prev, .carousel-control-next{
        width: 50px;
    }
    .container-slide{
        .slide-caption{
            h2.title-slide{
                font-size: 26px;
            }
            .slide-text p.text-body{
                font-size: 14px;
            }
        }
    }
    h1.title{
        font-size: 26px;
    }
    h2.title {
        font-size: 22px;
    }

    .testimonial{
        padding: 15px 0;
        .group{
            text-align: center;
            .name h5{
                font-size: 16px;
            }
            .text-body{
                padding-top: 0;
                font-size: 14px;
            }
        }
    }
    .partner .list-partner .content-partner .partner-text{
        display: none;
    }

}
@media only screen and (max-width: 992px) {
    h2.title-slide {
        font-size: 30px ;
    }
    .title {
        font-size: 20px ;
    }
}
@media only screen and (max-width: 768px) {
    .container-slide{
        //width: 65%;
        .slide-caption{
            h2.title-slide{
                font-size: 16px;
            }
            .slide-text p.text-body{
                font-size: 12px;
            }
            .btn-slide{
                padding: 5px 10px;
                font-size: 12px;
            }
        }
    }
    .text-body{
        padding-top: 0;
    }
    h1.title{
        font-size: 18px;
    }
    .btn-box {
        a {
            padding: 5px 10px;
            font-size: 12px;
        }
    }
    .subscribe {
        .container form{
            .input-group {
                width: 100%;
                margin: auto;
            }
        }
    }
    .why .swiper-slide .course-box {
        h4.title,.sub-title{
            font-size: 12px;
        }
    }
    .mentor,.testimonial .group {
        h4.name,.pos ,.name h5{
            font-size: 12px;
        }
    }
    .list-cus-say .row.swiper-wrapper .testimonial {
        padding: 20px 0;
    }
}
@media (max-width: 768px) {
    .swiper-button-next {
        right: 5px;
        transform: rotate(0);
        width: 28px;
        height: 28px;
        opacity: 0.8;
        &::after {
            height: 28px !important;
            font-size: 11px;
        }
    }
    .swiper-button-prev {
        left: 5px;
        transform: rotate(0);
        width: 28px;
        height: 28px;
        opacity: 0.8;
        &::after {
            height: 28px !important;
            font-size: 11px;
        }
    }
    .container.quote {
        background: $main;
        margin-bottom: 5%;
        padding: 10% 0;
        text-align: center;
        box-shadow: 0 3px 10px rgb(0 0 0 / 8%);
        h2.title{
            color: #ffffff;
            font-size: 22px;
            margin: 0;
        }
    }
}
@media only screen and (max-width: 576px) {
    .carousel-control-prev, .carousel-control-next{
        font-size: 16px;
        width: 20px;
    }
    .carousel-indicators{
        display: none;
    }

    .container-slide{
        width: 60%;
        .slide-caption{
            h2.title-slide{
                font-size: 16px;
                &.two-line{
                    -webkit-line-clamp:4;
                }
            }

            .slide-text{
                display: none;
                //p.text-body{
                //    font-size: 12px;
                //}
            }
            .btn-slide{
                padding: 5px 10px;
                font-size: 12px;
            }
        }
    }
    h2.title-slide {
        font-size: 15px;
    }
    .text-body {
        padding-top: 0;
        font-size: 12px;
    }
    h2.title ,h4.title{
        font-size: 18px;
    }
    .container.quote {

        h2.title{
            font-size: 18px;

        }
    }
    .subscribe .container form .input-group{
        width: 100%;
    }

}
@media only screen and (max-width: 480px) {
    .container-slide .slide-caption h2.title-slide {
        font-size: 14px;
    }
}
