.swiper-slide {
    &:nth-child(1),
    &:nth-child(7n-7) {
        .course-box {
            background-color: $blue-main;
        }
    }

    &:nth-child(2),
    &:nth-child(8n-8) {
        .course-box {
            background-color: $green-main;
        }
    }

    &:nth-child(3),
    &:nth-child(5n-5) {
        .course-box {
            background-color: $violet-main;
        }
    }

    &:nth-child(4),
    &:nth-child(6n-6) {
        .course-box {
            background-color: $orange-main;
        }
    }

    .course-box {
        width: 100%;
        height: 100%;
        padding: 12% 0;
        justify-content: center;
        color: white;
        border-radius: 20px;
        box-shadow: 0 5px 10px 1px rgb(0 0 0 / 16%);

        .content-box {
            width: 100%;
            padding: 0 20px;

            .icon-course {
                padding-bottom: 10px;
                text-align: left;

                img {
                    width: 60px;
                }
            }

            .content {
                text-align: left;
                h4 {
                    font-size: 16px;
                    color: #fff;
                    //width: 100%;
                    //overflow: hidden;
                    //float: left;
                    //-webkit-transition: 0.5s ease-in;
                    //-moz-transition: 0.5s ease-in;
                    //-o-transition: 0.5s ease-in;
                    //transition: 0.5s ease-in;
                    //&:hover {
                    //    -webkit-transition: 4s ease-in;
                    //    -moz-transition: 4s ease-in;
                    //    -o-transition: 4s ease-in;
                    //    transition: 4s ease-in;
                    //    text-indent: -300px;
                    //}
                }

                .sub-title {
                    line-height: 18px;
                    height: 36px;
                    strong {
                        font-size: 14px;
                        margin-bottom: 0;
                        color: #fff;

                    }
                }
                .des{

                }
            }
        }
    }
}

@media only screen and (max-width: 576px) {
    .swiper-slide .course-box {
        padding: 15px 0;

        .content-box {
            padding: 0 15px;

            .icon-course {
                width: 40px;
                margin: 0 auto;
            }

            .content {
                text-align: center;

                h4.title {
                    font-size: 14px;
                }

                .sub-title {
                    font-size: 12px;
                }
            }

        }
    }
}
